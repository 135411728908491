import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import "./Nav.scss"
import logo from "../../resources/images/navlogo.webp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faLocationPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

function Nav() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });
    const [isMobile, setIsMobile] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 8)
            });
        }
    }, []);

    // Function to update isMobile state based on screen width
    const updateIsMobile = () => {
        if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        // Update isMobile state on component mount and window resize
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };
    }, []);


    return (
        <div className="nav-container">

            <div className="top-nav-container">

                <div className="top-nav-data-container">

                    <h4 className="top-nav-data-name">Doctora Laura Elba Meza Trillo</h4>

                    <div className="top-nav-single-data">
                        <FontAwesomeIcon className="top-nav-data-icon" icon={faEnvelope}></FontAwesomeIcon>
                        <h4 className="top-nav-data-text">actitud.imagen@gmail.com</h4>
                    </div>

                    <div className="top-nav-single-data">
                        <FontAwesomeIcon className="top-nav-data-icon" icon={faLocationPin}></FontAwesomeIcon>
                        <h4 className="top-nav-data-text">World Trade Center, CDMX</h4>
                    </div>

                    <div className="top-nav-single-data">
                        <FontAwesomeIcon className="top-nav-data-icon" icon={faPhone}></FontAwesomeIcon>
                        <h4 className="top-nav-data-text">+52 55 7717 1297</h4>
                    </div>

                </div>

                <div className="top-nav-socials-container">
                    <FontAwesomeIcon className="top-nav-social-icon" icon={faInstagram}></FontAwesomeIcon>
                    <FontAwesomeIcon className="top-nav-social-icon" icon={faFacebook}></FontAwesomeIcon>
                    <FontAwesomeIcon className="top-nav-social-icon" icon={faLinkedin}></FontAwesomeIcon>
                </div>

            </div>

            {
                isMobile ? (
                    <div className="mobile-menu">
                        <button className="mobile-menu-button" onClick={toggleDropdown}>&#9776;</button>
                        {isDropdownOpen && (
                            <div className="mobile-dropdown">
                                <Link className='mobile-dropdown-link' to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inicio</Link>
                                <Link className='mobile-dropdown-link' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Sobre Mi</Link>
                                <Link className='mobile-dropdown-link' to="services" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Mis Servicios</Link>
                                <Link className='mobile-dropdown-link' to="contact" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Contactame</Link>
                            </div>
                        )}
                        <div className='mobile-menu-title-container'>
                            <img className='mobile-menu-img' src={logo} alt='Cargando...'></img>
                            <Link className='mobile-menu-title' onClick={updateIsMobile} to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Malederma SPA</Link>
                        </div>
                    </div>
                ) : (
                    <div className="main-nav-container">

                        <Link className='nav-logo-container' to="home" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>
                            <img className="nav-logo" src={logo} alt="Cargando..."></img>
                        </Link>

                        <div className="nav-links-container">
                            <Link className='nav-link' to="home" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Inicio</Link>
                            <Link className='nav-link' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Sobre Mi</Link>
                            <Link className='nav-link' to="services" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Mis Servicios</Link>
                            <Link className='nav-link' to="contact" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Contactame</Link>
                        </div>
                    </div>
                )
            }


        </div>
    )
}

export default Nav;