import React from "react";
import "./Contact.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faLinkedin, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faSquarePhone } from "@fortawesome/free-solid-svg-icons";

function Contact() {

    return (
        <div className="contact-container" id="contact">

            <div className="contact-title-container">
                <h2 className="contact-title">Contáctame</h2>
                <h4 className="contact-text">Estoy disponible para responder tus preguntas y brindarte información detallada sobre mis servicios. ¡Contáctame para comenzar tu viaje hacia una apariencia más radiante y saludable!</h4>
            </div>

            <div className="contact-items-container">
                <a className="contact-single" href="tel:+5217224244461" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="contact-single-icon" icon={faSquarePhone}></FontAwesomeIcon>
                    <h3 className="contact-single-data">+52 1 722 424 4461</h3>
                </a>

                <a href="https://www.linkedin.com/in/laura-elba-meza-trillo-a79663298/" target="_blank" rel="noreferrer" className="contact-single">
                    <FontAwesomeIcon className="contact-single-icon" icon={faLinkedin}></FontAwesomeIcon>
                    <h3 className="contact-single-data">Laura Elba Meza Trillo</h3>
                </a>

                <a href="https://www.instagram.com/lauramezatrillo/" target="_blank" rel="noreferrer" className="contact-single">
                    <FontAwesomeIcon className="contact-single-icon" icon={faInstagramSquare}></FontAwesomeIcon>
                    <h3 className="contact-single-data">lauramezatrillo</h3>
                </a>

                <a href="https://www.facebook.com/DRA.MALEDERMA" target="_blank" rel="noreferrer" className="contact-single">
                    <FontAwesomeIcon className="contact-single-icon" icon={faFacebookSquare}></FontAwesomeIcon>
                    <h3 className="contact-single-data">Laura Meza Trillo</h3>
                </a>

            </div>
        </div>
    )
}

export default Contact;