// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import Nav from './components/Nav/Nav';
import Home from './components/Home/Home';
import SendWpp from './components/SendWpp/SendWpp';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import spinner from './resources/images/spinner.webp';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAssets = async () => {
      // Simulate loading assets
      // Replace this with your actual asset loading logic
      try {
        // Load necessary assets
        await Promise.all([
          // Load your assets here
        ]);
      } catch (error) {
        console.error('Error loading assets:', error);
      } finally {
        // Set loading to false when assets are loaded or after a timeout
        setTimeout(() => {
          setLoading(false);
        }, 2000); // Simulating a 2-second delay
      }
    };

    loadAssets();
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="spinner-container">
          <div className="spinner">
            <div className="rotating-circle">
              {/* Adjust the circle content if needed */}
            </div>
            <div className="rotating-shadow"></div>
            <div className="circle-image-container">
              <img src={spinner} alt="Your Image" className="circle-image" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Nav />
          <Home />
          <About />
          <SendWpp />
          <Services />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
