import React from "react";
import "./Home.scss"
import logo from "../../resources/images/homelogo.webp"
import mobilelogo from "../../resources/images/mobilelogo.webp"
import { Link } from "react-scroll";
import { useRef, useLayoutEffect, useState } from 'react';

function Home() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 8)
            });
        }
    }, []);

    return (
        <div className="home-container" id="home">
            <div className="home-title-container">
            <img className="home-logo" src={logo} alt="Cargando..."></img>
            <img className="home-logo-mobile" src={mobilelogo} alt="Cargando..."></img>

                <h1 className="home-main-title">Laura Meza</h1>
                <h2 className="home-sub-title">MÉDICA GENERAL</h2>
            </div>

            <Link
                className="home-button"
                to="about"
                spy={true}
                smooth={true}
                offset={dimensions.offsetHeight - 50}
                duration={1500}>Empezar
            </Link>
        </div>
    )
}

export default Home;