import React from "react";
import "./About.scss"
import pic from "../../resources/images/about.webp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-scroll";
import { useRef, useLayoutEffect, useState } from 'react';

function About() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 8)
            });
        }
    }, []);

    return (
        <div className="about-container" id="about">

            <div className="about-profile-container">
                <img className="about-profile-pic" src={pic} alt="Cargando..."></img>
                <h2 className="about-profile-name">Laura Elba Meza Trillo</h2>
                <h3 className="about-profile-title">Medica General</h3>
                <h4 className="about-profile-data">Numero de Contacto: +52 1 722 4244461</h4>
                <h4 className="about-profile-data">Cedula Profesional: 6115099</h4>
                <h4 className="about-profile-data">Universidad de Estudios: Universidad Quetzalcóatl de Irapuato</h4>
                <a className="about-profile-btn" href="https://wa.me/+5217224244461" target="_blank" rel="noreferrer">Envíame un mensaje</a>

                <div className="about-profile-icons-container">
                    <a className="about-profile-a-icon" href="https://www.instagram.com/lauramezatrillo/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="about-profile-icon" icon={faInstagram}></FontAwesomeIcon>
                    </a>

                    <a className="about-profile-a-icon" href="https://www.facebook.com/DRA.MALEDERMA" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="about-profile-icon" icon={faFacebook}></FontAwesomeIcon>
                    </a>

                    <a className="about-profile-a-icon" href="https://www.linkedin.com/in/laura-elba-meza-trillo-a79663298/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="about-profile-icon" icon={faLinkedin}></FontAwesomeIcon>
                    </a>

                    <a className="about-profile-a-icon" href="https://wa.me/+5217224244461" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="about-profile-icon" icon={faWhatsapp}></FontAwesomeIcon>
                    </a>

                </div>
            </div>

            <div className="about-text-container">
                <h1 className="about-text-title">¿Quien soy?</h1>
                <h4 className="about-text-textbox">Soy la Dra. Laura Elba Meza Trillo, medica general, con más de 15 años de experiencia. Mi enfoque integral combina habilidad médica y empatía para tratar a mis pacientes y mantenerlos saludables. Graduada de La Universidad Quetzalcóatl de Irapuato, me mantengo a la vanguardia de los avances en el campo. Priorizo la transparencia y la seguridad, asegurando que mis pacientes tomen decisiones informadas. </h4>
                <Link
                    className="about-text-btn"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={dimensions.offsetHeight - 50}
                    duration={1500}>Mis Servicios
                </Link>
            </div>

        </div>
    )
}

export default About;