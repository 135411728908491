import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import "./Footer.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import bluevista from '../../resources/images/BlueVista.webp'
import { Link } from "react-scroll";

function Footer() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 8)
            });
        }
    }, []);

    return (
        <div className="footer-container">

            <div className="footer-main-container">

                <div className="footer-main-column-container">
                    <h2 className="footer-main-column-title">Mapa del Sitio</h2>
                    <Link className='footer-main-column-item' to="home" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Inicio</Link>
                    <Link className='footer-main-column-item' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Sobre Mi</Link>
                    <Link className='footer-main-column-item' to="services" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Mis Servicios</Link>
                    <Link className='footer-main-column-item' to="contact" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Contactame</Link>
                </div>

                <div className="footer-main-column-container">
                    <h2 className="footer-main-column-title">Laura Elba Meza Trillo</h2>
                    <h4 className="footer-main-column-item">Numero de Contacto: <br></br>+52 1 722 4244461</h4>
                    <h4 className="footer-main-column-item">Cedula Profesional: <br></br>6115099</h4>
                    <h4 className="footer-main-column-item">Universidad de Estudios: <br></br>Universidad Quetzalcóatl de Irapuato</h4>
                </div>

            </div>

            <div className="footer-bottom-container">
                <a className="footer-bottom-name" href='https://www.facebook.com/profile.php?id=61554416925322' target="_blank" rel="noreferrer">Sitio web desarrollado por: &nbsp; <img className="footer-bottom-name-img" src={bluevista} alt="Cargando..."></img> Blue Vista</a>

                <div className="footer-bottom-social-container">
                    <FontAwesomeIcon className="footer-bottom-icon" icon={faWhatsapp}></FontAwesomeIcon>
                    <FontAwesomeIcon className="footer-bottom-icon" icon={faInstagram}></FontAwesomeIcon>
                    <FontAwesomeIcon className="footer-bottom-icon" icon={faFacebook}></FontAwesomeIcon>
                </div>
            </div>

        </div>
    )
}

export default Footer;