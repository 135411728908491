import React from "react";
import "./Services.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyringe, faChalkboardTeacher, faComments, faPlus } from "@fortawesome/free-solid-svg-icons";

function Services() {

    return (
        <div className="services-container" id="services">

            <div className="services-title-container">
                <h2 className="services-title">Mis Servicios</h2>
                <h4 className="services-text">Mi enfoque integral garantiza procedimientos seguros y avanzados. Además, comparto mi experiencia contribuyendo al avance constante en el campo de la medicina.</h4>
            </div>

            <div className="services-items-container">

                <div className="services-single-item">
                    <FontAwesomeIcon className="services-single-icon" icon={faPlus}></FontAwesomeIcon>
                    <h3 className="services-single-title">Consultas</h3>
                    <h4 className="services-single-text">Ofrezco consultas medicas de vanguardia, utilizando tecnologías avanzadas para cuidar de su salud y bienestar.</h4>
                </div>

                <div className="services-single-item">
                    <FontAwesomeIcon className="services-single-icon" icon={faComments}></FontAwesomeIcon>
                    <h3 className="services-single-title">Asesoría</h3>
                    <h4 className="services-single-text">Brindo asesoramiento experto en medicina a mis pacientes, guiandolos para que tomen decisiones informadas.</h4>
                </div>

                <div className="services-single-item">
                    <FontAwesomeIcon className="services-single-icon" icon={faChalkboardTeacher}></FontAwesomeIcon>
                    <h3 className="services-single-title">Enseñanza</h3>
                    <h4 className="services-single-text">Comparto mi experiencia a otros profesionales de la salud, contribuyendo al avance continuo en el campo de la medicina.</h4>
                </div>

            </div>

        </div>
    )
}

export default Services;