import React from "react";
import "./SendWpp.scss"


function SendWpp() {

    return (
        <div className="send-wpp-container">
            <div className="send-wpp-text-container">
                <h2 className="send-wpp-title">¡Enviame un WhatsApp!</h2>
                <h4 className="send-wpp-text">¡Chatea conmigo!, estare encantada de ayudarte a resolver tus dudas y agendar tu cita.</h4>
            </div>

            <div className="send-wpp-btn-container">
                <a className="send-wpp-btn" href="https://wa.me/+5217224244461" target="_blank" rel="noreferrer">¡Hablemos!</a>
            </div>
        </div>
    )
}

export default SendWpp;